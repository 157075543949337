@use './config/' as *;
@forward "./main-scss/";
@forward "./form/common";
.waf-profile {
    .site-token {
        width: unset;
        @extend %d-block;
        @extend %static;
    }
    .avatar-section {
        @extend %relative;
        .avatar {
            object-fit: cover;
            @extend %h-100;
            @extend %w-100;
        }
        .image-upload {
            @extend %d-none;
        }
    }
    .site-token {
        .token-list {
            @include grid(3, var(--space-0));
        }
        .token-item {
            position: relative;
            &:not(:last-child)::before {
                content: '';
                background: linear-gradient(0deg, transparent, var(--neutral-300) 49.5%, transparent);
                opacity: .3;
                width: 0.1rem;
                right: 0;
                @extend %h-100;
                @extend %d-block;
                @extend %absolute;
            }
        }
        .icon {
            @extend %mb-2;
        }
        .icon::before {
            @include square(3.5rem);
        }
        .value {
            flex-direction: column;
            @extend %transparent-bg;
        }
        .number {
            @extend %ml-0;
            @extend %font-20-pb;
        }
    }
    .waf-head {
        @extend %mb-3;
        @extend %relative;
        @extend %flex-sb-c;
        .title {
            @extend %neutral-50;
        }
    }
    .form-setting {
        .form-dropdown {
            right: 0;
        }
        .btn-setting {
            padding: var(--space-2) var(--space-6);
            @extend %half-radius;
            @extend %secondary-900-bg;
            &::after {
                @include icon(setting, 14);
                @extend %primary-500;
                @extend %ml-1;
            }
        }
        .btn-text {
            @extend %primary-500;
            @extend %font-12-pr;
        }
    }
    .profile {
        &-card {
            @extend %neutral-50-bg;
            .profile-jersey {
                @include background(null, "patterns/user-profile-pattern.jpg", center/cover no-repeat);
                @extend %mx-auto;
                @extend %relative;
                @extend %flex-c-c;
            }
            .jersey-wrapper {
                position: absolute;
                flex-direction: column;
                @extend %flex-c-c;
            }
            .img-box {
                top: var(--space-6);
                @extend %relative;
                @extend %transparent-bg;
                @extend %px-4;
                img {
                    @extend %static;
                }
            }
            .full-name {
                @extend %mb-3;
            }
            .first-name {
                @extend %d-none;
            }
            .name {
                @extend %font-24-pb;
                @extend %neutral-50;
                @extend %uppercase;
            }
            .jersey-number {
                @extend %font-58-pb;
                @extend %neutral-50;
            }
            .profile-about {
                line-height: 2.3rem;
                @extend %p-3;
                @extend %font-14-pr;
            }
            .profile-jersey {
                @extend %hidden;
            }
        }
        &-form {
            @extend %neutral-50-bg;
            @extend %p-3;
            .profile-form-head {
                border-bottom: .1rem solid clr(neutral-100, 5);
                @extend %pb-2;
                @extend %mb-4;
                @extend %flex-n-c;
            }
            .form-control {
                border: 0;
                box-shadow: none;
                height: unset;
                margin-top: -.2rem;
                @extend %transparent-bg;
                @extend %px-0;
                @extend %font-16-pb;
                @extend %d-none;
            }
            .form-dropdown,
            .btn-country,
            .btn-gender {
                @extend %d-none;
            }
            .full-name {
                gap: var(--space-1);
                @extend %flex-n-n;
                @extend %ml-2;
            }
            .name {
                @extend %font-22-pr;
                @extend %uppercase;
                @extend %secondary-1000;
            }
            .last-name {
                font-weight: 700;
            }
        }
        &-form-list {
            @include grid(1, var(--space-3));
            @extend %pl-0;
        }
        &-form-item {
            word-wrap: break-word;
            @extend %secondary-100-bg;
            @extend %p-3;
            @extend %half-radius;
            @extend %relative;
        }
        &-form-label {
            @extend %font-14-pr;
            @extend %neutral-600;
            @extend %mb-2;
        }
        &-form-value {
            @extend %font-16-pb;
            @extend %neutral-700;
        }
        &-form-body {
            .btn-gender,
            .btn-country {
                @extend %w-100;
                @extend %text-left;
                @extend %d-none;
                &::after {
                    @include icon(chevron-down, 12);
                }
            }
            .btn-text {
                @extend %font-14-pb;
                @extend %neutral-1000;
            }
            .form-dropdown {
                left: 0;
                border-top: 0.1rem solid clr(neutral-100, 4);
                @extend %mt-2;
                @extend %secondary-100-bg;
                @extend %w-100;
            }
            .sub-ul {
                @extend %pl-0;
            }
        }
        &-form-wrap {
            gap: var(--space-3);
            @extend %flex-column-n-n;
        }
    }
    .favourite {
        &-player {
            @extend %p-3;
            @extend %neutral-50-bg;
        }
        &-player-head {
            @extend %pb-3;
            .control-label {
                @extend %uppercase;
                @extend %secondary-1000;
                @extend %font-16-pb;
            }
            .btn-edit::after {
                top: -.2rem;
                @include icon(edit, 11);
                @extend %flex-c-c;
                @extend %secondary-1000;
                @extend %relative;
            }
            .form-dropdown {
                width: 19rem;
                .btn-list {
                    @extend %p-0;
                    .btn-text {
                        @extend %flex-n-c;
                    }
                }
                .selected-text {
                    border-bottom: .1rem solid clr(neutral-100, 5);
                    @extend %secondary-1000;
                    @extend %font-10-pb;
                    @extend %p-2;
                    @extend %mb-2;
                    @extend %uppercase;
                }
                .sub-ul {
                    gap: var(--space-3);
                    @extend %pb-2;
                    @extend %flex-column-n-n;
                }
                .item-list:not(:last-child)::after {
                    content: "";
                    height: 0.1rem;
                    bottom: -0.8rem;
                    @extend %w-100;
                    @extend %d-block;
                    @extend %absolute;
                    @extend %neutral-100-bg-5;
                }
            }
            .player-image {
                border: .1rem solid clr(primary-500);
                @include square(4.4rem);
                @extend %circle-radius;
                @extend %p-1;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
            .btn-list {
                @extend %flex-n-c;
                @extend %w-100;
            }
            .name {
                @extend %font-12-pb;
                @extend %secondary-1000;
                @extend %capitalize;
            }
            .full-name {
                @extend %flex-column-n-fs;
                @extend %ml-2;
            }
        }
        &-player-image {
            @include background(null, "patterns/user-profile-pattern.jpg", no-repeat);
            @extend %flex-c-n;
            .player-image {
                width: 20rem;
            }
        }
    }
    .btn-view-profile {
        @extend %relative;
    }
    .player {
        &-info-wrap {
            gap: var(--space-2);
            @extend %flex-column;
            @extend %mt-2;
            .icon-list {
                gap: var(--space-2);
                @extend %flex-c-c;
                .icon {
                    &::after {
                        @include square(2.8rem);
                        @include flex-config(flex, null, center, center);
                        @extend %circle-radius;
                        @extend %neutral-50;
                        @extend %secondary-700-bg;
                        @extend %font-12;
                    }
                    &.icon-captain::after {
                        content: "C";
                    }
                    &.icon-overseas::after {
                        @include icon(flight);
                        @extend %secondary-1000;
                        @extend %primary-500-bg;
                    }
                }
            }
        }
        &-info-list {
            gap: var(--space-2);
            @extend %pb-4;
            @extend %flex-c-c;
            @extend %relative;
            &::after {
                content: '';
                opacity: 0.3;
                height: 0.1rem;
                background: linear-gradient(90deg, transparent, var(--neutral-300) 49.5%, transparent);
                bottom: var(--space-1);
                @extend %w-100;
                @extend %pos-x-center;
            }
        }
        &-country {
            width: 3.6rem;
            @extend %flex-n-c;
            @extend %d-none;
            .country-name {
                @extend %font-14;
                @extend %ml-1;
                @extend %neutral-1000;
            }
        }
        &-role {
            gap: var(--space-2);
            @extend %flex-c-n;
            .role {
                @extend %font-10-pb;
                @extend %secondary-1000;
                @extend %secondary-300-bg;
                @extend %half-radius;
                @extend %p-1;
                @extend %capitalize;
            }
        }
        &-name {
            @extend %text-center;
            .last-name {
                font-weight: 700;
            }
            .name {
                @extend %secondary-1000;
                @extend %font-26-pr;
            }
        }
        &-content {
            @extend %pt-6;
            @extend %relative;
            &::after {
                content: '';
                opacity: 0.3;
                height: 0.1rem;
                background: linear-gradient(90deg, transparent, var(--neutral-300) 49.5%, transparent);
                top: var(--space-3);
                @extend %w-100;
                @extend %pos-x-center;
            }
            .title {
                @extend %font-16-pb;
                @extend %secondary-1000;
                @extend %capitalize;
                @extend %mb-3;
            }
            .stats {
                &-list {
                    @include grid(2, var(--space-3));
                }
                &-item {
                    @extend %text-center;
                    @extend %secondary-100-bg;
                    @extend %py-3;
                }
                &-number {
                    @extend %font-18-pb;
                    @extend %secondary-1000;
                }
                &-title {
                    @extend %font-10-pr;
                    @extend %neutral-600;
                }
            }
        }
    }
    .btn-view-profile {
        @extend %d-block;
        @extend %mx-auto;
    }
    .form-dropdown {
        width: 18rem;
        z-index: var(--z-index1);
        max-height: 16rem;
        overflow-y: auto;
        @include dropdown(close);
        @extend %secondary-200-bg;
        @extend %absolute;
        .selected-text {
            @extend %w-100;
            @extend %d-block;
            @extend %uppercase;
            @extend %font-12;
            @extend %neutral-1000;
        }
        .sub-ul {
            border-radius: 0 0 var(--half-radius) var(--half-radius);
            @extend %flex-column-n-n;
            @extend %w-100;
            @extend %hidden;
        }
        .btn {
            &-list {
                padding: 1.3rem 0 1.3rem var(--space-2);
                @extend %w-100;
                @extend %text-left;
            }
            &-text::before {
                @extend %mr-2;
                @extend %secondary-1000;
            }
            &-edit::before {
                @include icon(edit, 16);
            }
            &-delete::before {
                @include icon(delete, 16);
            }
            &-logout::before {
                @include icon(logout, 16);
            }
        }
        .list-item {
            @extend %w-100;
            @extend %relative;
            button {
                .btn-text {
                    @extend %font-12;
                    @extend %neutral-1000;
                }
            }
        }
    }
    .btn-edit,
    .btn-gender,
    .btn-country,
    .btn-setting {
        &[aria-expanded="true"] {
            & ~ .form-dropdown {
                @include dropdown(open);
            }
        }
    }
    &.edit-profile {
        .profile-form-value {
            display: none;
        }
        .profile-form-body {
            .form-control,
            .form-dropdown {
                display: block;
            }
            .btn-country,
            .btn-gender {
                @include flex-config(flex, null, space-between, null);
                @extend %flex-sb-n;
            }
            .profile {
                &-date,
                &-number {
                    .profile-form-value {
                        display: block;
                    }
                }
            }
        }
        .show-avatar {
            top: 0;
            &::after {
                cursor: pointer;
                @include icon(edit, 10);
                @extend %neutral-50;
                @extend %secondary-900-bg;
            }
        }
        .form-setting {
            .btn-setting {
                @extend %primary-500-bg;
                &::after {
                    @extend %font-0;
                    @extend %d-none;
                }
                .btn-text {
                    font-weight: 700;
                    @extend %mr-2;
                    @extend %secondary-1000;
                }
            }
            .btn-text {
                @extend %secondary-1000;
            }
        }
    }
    .btn-view-profile {
        @extend %uppercase;
    }
    .waf-modal {
        .modal-dialog {
            @extend %p-3;
        }
    }
}
@media screen and (min-width: $desktop-min-width) {
    .waf-profile {
        --_favourite-player-width: 20%;
        .favourite {
            &-player {
                margin-block: var(--space-3);
                overflow: hidden;
                width: 100%;
                flex-basis: 100%;
                padding: 0;
                position: relative;
                min-height: 28rem;
            }
            &-player-image {
                .player-image {
                    margin-left: 18rem;
                }
            }
            &-player-info {
                display: grid;
                grid-template-columns: var(--_favourite-player-width) 50% 1fr;
                padding-right: var(--space-3);
                height: 100%;
            }
            &-player-head {
                @include position(3rem, null, null, calc(var(--_favourite-player-width) + var(--space-18)));
            }
        }
        .profile {
            &-form-list {
                @include grid(2, var(--space-3));
            }
            &-info-wrap {
                flex-direction: row;
            }
            &-form-wrap {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
            &-card {
                width: calc(30% - var(--space-1));
                flex-basis: calc(30% - var(--space-1));
                .profile-about {
                    font-size: 1.6rem;
                }
            }
            &-form {
                width: calc(70% - var(--space-1));
                flex-basis: calc(70% - var(--space-1));
            }
            &-form-wrap {
                gap: 0;
                flex-direction: row;
            }
        }
        .player {
            &-name {
                text-align: left;
                .name {
                    font-size: 3rem;
                }
            }
            &-info-wrap {
                align-items: flex-start;
                padding: var(--space-13) 0 var(--space-6) var(--space-19);
                margin-top: var(--space-3);
            }
            &-content {
                margin-left: auto;
                width: 100%;
                &::after {
                    display: none;
                }
                .stats {
                    &-number {
                        font-size: 2.4rem;
                    }
                    &-title {
                        font-size: 1.2rem;
                    }
                }
            }
            &-info-list {
                width: 100%;
                justify-content: flex-start;
            }
        }
        .btn-view-profile {
            margin-inline: unset;
            padding-inline: var(--space-10)
        }
        .form-setting {
            @include position(var(--space-12), var(--space-3), null, null, absolute);
        }
        .waf-modal {
            .modal-dialog {
                padding: var(--space-6);
            }
        }
    }
}